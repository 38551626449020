<template>
	<div>
		<div class="ui header">Home</div>
	</div>
</template>

<script>
	export default {
		name: "Home"
	};
</script>
